import ApiService from "@/core/services/api.service";

const RESOURCE = "student-enrollment-tracking";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStudentEnrollmentTrackings = () => {
  return ApiService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch data for a specific student enrollment tracking by ID
 * @param id
 * @returns {*}
 */
export const getStudentEnrollmentTracking = (id) => {
  return ApiService.get(`${RESOURCE}/${id}`);
};

/**
 * POST request to create a new student enrollment tracking
 * @param payload
 * @returns {*}
 */
export const createStudentEnrollmentTracking = (payload) => {
  return ApiService.post(`${RESOURCE}`, payload);
};

/**
 * PUT request to update a specific student enrollment tracking
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateStudentEnrollmentTracking = (id, payload) => {
  return ApiService.put(`${RESOURCE}/${id}`, payload);
};

/**
 * DELETE request to delete the specified student enrollment tracking
 * @param id
 * @returns {*}
 */
export const deleteStudentEnrollmentTracking = (id) => {
  return ApiService.delete(`${RESOURCE}/${id}`);
};

/**
 * GET request to fetch all tracking records by student UUID
 * @param studentUuid
 * @returns {*}
 */
export const getStudentEnrollmentTrackingByStudentUuid = (studentUuid) => {
  return ApiService.get(`${RESOURCE}/student/${studentUuid}`);
};

export default {
  getAllStudentEnrollmentTrackings,
  getStudentEnrollmentTracking,
  createStudentEnrollmentTracking,
  updateStudentEnrollmentTracking,
  deleteStudentEnrollmentTracking,
  getStudentEnrollmentTrackingByStudentUuid,
};
