<script>
export default {
  name: "EnrollmentErrorModal",
  data() {
    return {
      dialogState: false,
    }
  },

  methods: {
    toggleModal() {
      this.dialogState = !this.dialogState;
    },
  }
}
</script>

<template>
  <v-dialog
      v-model="dialogState"
      max-width="600"
      transition="dialog-bottom-transition"
  >
    <v-container class="white pa-5 d-flex justify-center flex-column">
      <v-row class="mb-5">
        <v-col class="d-flex justify-center">
          <v-avatar class="red lighten-4" size="130">
            <v-icon color="red" x-large>mdi-account-alert-outline</v-icon>
          </v-avatar>
        </v-col>
      </v-row>
      <p class="text-h5 text-center font-weight-bold">Hay un conflicto con la información</p>
      <p class="text-h6 font-weight-regular "></p>

      <v-card class="grey lighten-4 rounded-lg pa-4 mb-6">
        <div class="d-flex justify-content-between">
          <p class="text-h5 font-weight-medium mb-0 mt-1">¿Cómo solucionarlo?</p>
          <v-icon color="amber" right>mdi-lightbulb-on-10</v-icon>
        </div>
        <v-divider></v-divider>
        <p>Para solventar, es necesario corroborar que toda la información del sostenedor económico coincida con uno de
          los responsables ante la institución. De lo contrario no es posible generar el reporte.</p>
      </v-card>
      <v-btn class="primary lighten-4" @click="toggleModal()">
        <p class="primary--text mb-0 font-weight-bold">Cerrar</p>
      </v-btn>
    </v-container>
  </v-dialog>
</template>

<style scoped>

</style>
