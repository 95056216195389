import Axios from "axios";
import { studentService } from "@/core/services/studentApi.service";

export const registerStudentEconomicManager = (payload) => {
  return studentService.post("economic-managers", payload);
};

export const registeredStudentEconomicManager = (studentUUID) => {
  return studentService.get(`economic-managers/${studentUUID}`);
};

// SGE API

const SGE_BASE_URL = "https://sge.ricaldone.edu.sv";

const SGE_PAYMENTS_ENDPOINT = `${SGE_BASE_URL}/publico/php/pago_externo.php`;

const currentYear = new Date().getFullYear();

export const deleteSelectedPayments = (studentCode) =>
  Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: "eliminarSeleccionados",
    codigo: studentCode,
  });

export const makePayment = (payload) =>
  Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: "pago",
    monto: payload.monto,
    nombre: payload.nombre,
    tarjeta_numero: payload.tarjeta_numero,
    tarjeta_vencimiento: payload.tarjeta_vencimiento,
    tarjeta_cvv: payload.tarjeta_cvv,
    codigo: payload.codigo,
  });

export const addPayment = (payload) =>
  Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: "agregar",
    concepto: payload.concepto,
    monto: payload.monto,
    correlativo: payload.correlativo,
    codigo: payload.code,
  });

export const deletePayment = (payload) =>
  Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: "eliminar",
    concepto: payload.concepto,
    codigo: payload.code,
  });

export const getPaymentsHistory = (studentCode) =>
  Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: "historial",
    codigo: studentCode,
  });

export const getTotal = (studentCode) =>
  Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: "total",
    codigo: studentCode,
  });

// CONTA API

const CONTA_API_URL = "https://contapi.ricaldone.edu.sv/public/api";

const ONLINE_PAYMENTS_RESOURCE = "student-projection-payments";

/**
 * This API call retrieves both pending and paid payments of the specified student
 * If year is not specified it will consult the current year
 */
export const getAllPayments = ({ studentCode, anioDeEjercicioAConsultar }) =>
  Axios.get(
    `${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/all/${
      anioDeEjercicioAConsultar || currentYear
    }/${studentCode}`
  );

/**
 * This API call retrieves pending payments of the specified student
 * If year is not specified it will consult the current year
 */
export const getAllPendingPayments = ({
  studentCode,
  anioDeEjercicioAConsultar,
}) =>
  Axios.get(
    `${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/not-paid/${
      anioDeEjercicioAConsultar || currentYear
    }/${studentCode}`
  );

/**
 * This API call retrieves paid payments of the specified student
 * If year is not specified it will consult the current year
 */
export const getAllPaidPayments = ({
  studentCode,
  anioDeEjercicioAConsultar,
}) =>
  Axios.get(
    `${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/paid/${
      anioDeEjercicioAConsultar || currentYear
    }/${studentCode}`
  );

/**
 * This API call retrieves paid payments of the specified student
 * If year is not specified it will consult the current year for a quick check at enrollment process step 1
 */
export const getAllPaidPaymentsAdmissionCheck = (
  studentCode,
  anioDeEjercicioAConsultar
) =>
  Axios.get(
    `${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/check-admision-payment/${anioDeEjercicioAConsultar}/${studentCode}`
  );

export const getPagosPendientesPorAnio = (
  studentCode,
  anioDeEjercicioAConsultar
) =>
  Axios.get(
    `${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/check-pending-payments-status/${anioDeEjercicioAConsultar}/${studentCode}`
  );

export default {
  deleteSelectedPayments,
  makePayment,
  getPaymentsHistory,
  getAllPayments,
  getAllPendingPayments,
  getAllPaidPayments,
  getAllPaidPaymentsAdmissionCheck,
  getTotal,
  registerStudentEconomicManager,
  registeredStudentEconomicManager,
  addPayment,
  deletePayment,
  getPagosPendientesPorAnio,
};
